html {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }
  
  .box-shadow-footer {
    box-shadow:  rgba(0, 0, 0, .05);
    background-color: rgba(0,29,48,1);
    white-space: nowrap;
    text-align: center;
    font-family: "Segoe UI", "Arial"; 
	  font-size:14px;
    font-style: normal;
    color:white;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 66px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
    /* background-color: #f5f5f5; */
  }
  /* .container {
    width: auto;
    max-width: 680px;
    padding: 0 15px;
  } */
  /* .footer{
      padding-top: 14%;
  } */

  .footer {
    margin-top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
 }

 @media (max-width: 768px) {

  .box-shadow-footer {
    font-size:13px;
  }
 }

 @media (max-width: 600px) {

  .box-shadow-footer {
    font-size:12px;
  }
 }