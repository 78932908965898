

.ok-page, .ko-page{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 767px) {
    .ok-page .btn-go-home,
    .ko-page .btn-go-home {
      width: 100%;
    }
}

.ok-page .btn-go-home,
.ko-page .btn-go-home {
    width: 50%;
}

.ok-page .message, .ko-page .message{
    font-family: segoe UI;
    font-size: 21px;
    font-weight: 600;
    vertical-align: central;
    text-align: center;
    margin-right: 10;
    margin-top: 1.5em;
}

.ko-page .message.ko{
    color:#ef2626;
}

.ok-page .message.ok{
    color:#2bb097;
}