.fondoDetalle {
    background-color: white;
    padding: 5px 10px 0px 10px;
    margin: auto;
}

.fontBold {
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    color: rgba(0,29,48,1);
}

.fontNormalBold {
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0,29,48,1);
}

.fontNomal {
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    color: rgba(0,29,48,1);
}

.fontLimite {
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,29,48,1);
}