html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 60px; /* Margin bottom by footer height */
}
 /* .footer {
 position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here 
  line-height: 60px; /* Vertically center the text there 
  background-color: #f5f5f5; 
}*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.vertical-center {
  align-items: center;
  text-align: center;
  background-color: #F7F9F9;
}

.modal-dialog {
  max-width: none;
}

.container{
  padding-left: 10% !important;
  padding-right: 10% !important;
}

#divNeedHelp > p {
  font-size: 14px;
}

#divNeedHelpTitle {
  font-size: 21px;
}

.ModalTitle {
  padding-left:42px !important;
  padding-right:50px !important;
  padding-top:30px !important;
}

.ModalBody {
  padding-left:60px !important;
  padding-right:50px !important;
}

@media (max-width: 768px) {

  #divNeedHelp > p {
    font-size: 12px;
  }

  #divNeedHelpTitle {
    font-size: 19px;
  }

  .ModalTitle {
    padding-left:25px !important;
    padding-right:30px !important;
    padding-top:25px !important;
  }

  .ModalBody {
    padding-left:40px !important;
    padding-right:30px !important;
  }
  
}

