.AplazameDetails {
    font-family: "Segoe UI", "Arial"; 
	font-style:  normal;
    font-weight: normal;
	font-size: 14px;
    padding-left: 20px;
    margin-top: 15px;
}

.AplazameDetails > p {
    padding-right: 20px;
    color: rgb(112, 112, 112);
}

.AplazameDetails > button {
    background-color: rgb(201, 153, 0);
    border: rgb(201, 153, 0);
    color:white;
	font-family: "Segoe UI", "Arial";  
	height:45px;
    line-height:15px;
    width: 200px;
    font-size: 18px;
    font-weight: 700;
    border-radius: 5px;
}

.AplazameDetails > button:hover{
    background-color:  rgb(168,129,5);
}

.AplazameDetails > img {
    margin-left: 10px;
}