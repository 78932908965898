.DayPickerInput{
    display:block;
}

.lbl-inputs {
	/* position: absolute;
	left: 0px;
	top: 0px;
	overflow: visible;
	width: 84px; */
	white-space: nowrap;
	text-align: center;
	font-family: "Segoe UI", "Arial"; 
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	color: rgba(0,29,48,1);
}

.btn-continuar::before{
	content: url("data:image/svg+xml;charset=UTF-8, <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'	 viewBox='0 0 500 500 '  >	<g>		<path fill='white' d='M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z'/>	</g></svg>");
	width: 20px;
	float: right;
}

.btn-continuar{
    width: 100%;
    background-color: rgb(201, 153, 0);
    border: rgb(201, 153, 0);
    color:white;
    font-weight: 480;
	font-family: "Segoe UI", "Arial";  
	font-size:22px;
	height:45px;
	line-height:15px;
}


.btn-primary:hover{
    background-color:  rgb(168,129,5) !important;
}

.btn-primary:focus{
    background-color:  rgb(168,129,5) !important;
}


.text-normal {
	/* position: absolute;
	left: 0px;
	top: 0px;
	overflow: visible;
	width: 84px; */
	/* white-space: nowrap; */
	text-align: center;
	font-family: "Segoe UI", "Arial"; 
	font-style: normal;
	font-size: 14px;
    color: rgba(0,29,48,1);
}

.text-title {
	/* position: absolute;
	left: 0px;
	top: 0px;
	overflow: visible;
	width: 84px; */
	white-space: nowrap;
	text-align: center;
	font-family: "Segoe UI", "Arial"; 
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: rgba(0,29,48,1);
}

.text-dorado{
	/* position: absolute;
	left: 0px;
	top: 0px;
	overflow: visible;
	width: 84px; */
	white-space: nowrap;
    text-align: center;
    text-decoration: underline;
	font-family: "Segoe UI", "Arial"; 
	font-style:  normal;
    font-weight: normal;
    font-size: 16px;
    color:  rgba(201,153,0,1) !important;
}

.text-dorado:hover{
	text-decoration: none;
	color:  rgb(168,129,5) !important;
}

form  .divLocator   input {
	font-family: "Segoe UI", "Arial"; 
	font-style:  normal;
    font-weight: normal;
	font-size: 14px;
}

form .divLocator  input:focus{

	border: 1px solid rgba(201,153,0,1) !important;
	box-shadow: rgba(201,153,0,1)  0px 0px 0px !important;
}

form  .divLocator   input:hover{

	border: 1px solid rgba(201,153,0,1) !important;
	box-shadow: rgba(201,153,0,1) 0px 0px 0px  !important;
}

form  .divLocator   input:not(:placeholder-shown):required{
	background-color: white;
}

form  .divDatePicker   input {
	font-family: "Segoe UI", "Arial"; 
	font-style:  normal;
    font-weight: normal;
	font-size: 14px;
}

form .divDatePicker  input:focus{

	border: 1px solid rgba(201,153,0,1) !important;
	box-shadow: rgba(201,153,0,1)  0px 0px 0px !important;
}

form  .divDatePicker   input:hover{

	border: 1px solid rgba(201,153,0,1) !important;
	box-shadow: rgba(201,153,0,1) 0px 0px 0px  !important;
}

form  .divDatePicker   input:not(:placeholder-shown):required{
	background-color: white;
}

.DayPicker {
	font-size: 1rem;
	color:  #001D30;
}
  
.DayPickerInput-Overlay {
	background-color: #F7F9F9; /*#F7F9F9*/
	box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15);
}
  

.DayPicker-wrapper{
	border-radius: 7px;
	background-color: white;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DayPicker-Day {
	width: 2.6rem;
	height: 1.8rem;
}


.DayPicker-Caption {
    text-align: center;
	margin: 0;
	padding-bottom: 5px;
}

.DayPicker-NavButton:hover {
	opacity: 1;
}

.DayPicker-NavButton--prev {
	left: 10px;
	opacity: 0.8;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAAAXNSR0IArs4c6QAAATRJREFUWAnN170NwjAQhuFzIhqEqKBBVBQUFCwDu6VkLGagZQISKZE+ixh88f3Yje3u1ePGF6iWdTjvqFl3oYqe4/VG1HQUaO8bNKr0IfcJxi8IVKaYYbcPmlHxC0qo2Af9UbENylCxCWKo6AcxVfSCFqroBBWoyAYJqMgFCamUBwmrlAUpqCwLUlThBymr5AcZqeQFGar8DnJQSQc5qXwHOavEQRWoYFCDlxrOLb1fT9psHxRWp/7Lf/GOiqeOCp6vjUQq0IqFsM5JKxbCICettBDGGWqlhTDIUCtPCOOUtfKEMEhZiy+EcQpafCEMUtAqE8I4Ia0yIQwS0pITwrgCLTkhDCrQ0hHCOKaWjhAGMbX0hTAuQ0tfCIMytGyFMC6hZSuEQQktPyGMA606goa4cVj9AHVZV3hOfgqXAAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--prev:hover {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAAAXNSR0IArs4c6QAAAQJJREFUWAnN180NwjAMhmGHGWAy2K2CiTiwEoIWKVJQm9aOfz7nkuT26PUlKZRkve50fn9oKhk8zwddy4yZLRcoqFahQrcaBgZqq1TMsoeDtqrAQL0q4aCjKqEgTpUQkKSKO0haxQ00WsUFpKliCrKoYgayqqIGWVdRgTyqDIE8q4hB3lXYoKgqLFBklV0QokoXhKqyAqGr/IEyVGlBp/aS4fx75KcaWVslw/hW3yB0rRWoFkPV6oIWGKLWLghRiwWKrMUGRdUSg7xrDYE8a6lAHrXUIOtaZiCrWqYgi1ouIE0tN9BoLXeQtFYISFIrFMSpFQ46qgUD9WpBQVu1UoDaWl88e7ZXBHWCwgAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--next {
	right: 10px;
	opacity: 0.8;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAAAXNSR0IArs4c6QAAASVJREFUWAnN2SsOwkAQxvGvDQoNhhSLJL0LXKJno1wGgUATDGhkS3hsUtJuu4957JpJRv3yH7lAUR6w2iyQyMuRYY98fkax3aVgyj+IDEtkeZ1CrS/IpEmg1j/oDVOu1Qcp17KDlGqNgxRquYEEa7mDhGr5g5hrhYEYa8WBGGrFg4hr0YGIatGCCGrxgCJq8YECa/GDPGvJgDxqyYIcasmDJmrpgSy1MqzL1qBVZ4s70FS6hQYKzAZ28qsWNZpnhdvloQv6nQnX09FU0AN1qhjMe8qDBqrogSxV5EETVWRBDlVkQB5V+EGeVfhAgVV4QBFVaEEEVehARFXiQcRV4kAMVcJAjFX8QcxV3EFCVdxAglXGQQpV7CClKn2QcpUuCCl8uHRBLwdEuv/2bFwUAAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next:hover {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAAAXNSR0IArs4c6QAAASNJREFUWAnN170NwjAQhuFzYATYhII9YAYQGxFWAPYgEjVLwAooBEKkRIkT/9zdZxe2dG4eva5MxZHO95wWlMjKyND2TfS4nWiTgin7I5ampEsKtRpQ7UqgVhdUs6C1hkDQWnYQqNYUSL2WG0ixlg9IpZY/SLhWKEisVhxIoBYHiLUWH4ipFjcoupYMKKKWJCioljzIs5YWyLmWLsihFgI0WgsHstQyRU5lfQffn6WhA7pQr8K8N0EMqi/YrKqz2tELDfo903pP16YDDtSq0mC+JwLUq4IDWaogQKNVdEEOVbRAzlXkQZ5VJEFBVWRAEVW4QdFV+EBMVThArFXiQAJVQkFiVfxBwlV8QCpV3ECKVaZA6lXsIFCVIRC0SheUQJU26APhBcCPhRlhFQAAAABJRU5ErkJggg==');
}

input[readonly]
{
    background-color:white !important;
}

.divLocator > img{
	float: right;
	position: relative;
	top: -25px;
	right: 10px;
}

.divDatePicker > img{
	float: right;
	position: relative;
	top: -23px;
	right: 10px;
}

.divLocator > img:hover{
	cursor: pointer;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	opacity: 0.3 !important; /* Firefox */
}
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
	opacity: 0.3 !important;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
	opacity: 0.3 !important;
  }

@media (max-width: 768px) {

	.DayPicker {
   
		font-size: 0.8rem;
	}
	
	.divLocator > img {
		top: -23px;
	}

	.divDatePicker > img {
		top: -21px;
	}
  }
