.selectRadioButton{
    font-family: "Segoe UI", "Arial"; 
    text-align: left;
    padding: 0px;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 30px;
    margin: 0 auto;
    margin-bottom: 20px;
    background-color: white;
}

.divDisabled{
    pointer-events: none;
    opacity: 0.6;
    filter: alpha(opacity=50);
    zoom: 1;  
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";  
    -moz-opacity: 0.5; 
    -khtml-opacity: 0.5;  
}

.checkAcceptTerm {
    margin-right: 10px;
}

.divTerms {
    display: inline;
}

.btnNextStep {
    display:block;
    width:250px;
    background-color: transparent;
    margin: 0px auto;
    text-decoration: underline;
    font-size: 14px;
    text-decoration: none;
    border: 1px solid rgba(0,29,48,1);
    padding: 15px 20px 15px 20px;
    text-align: center;
    border-radius: 5px;
    font-size: 18px;
}

.btnNextStepDisabled {
    display:block;
    width:250px;
    background-color: transparent;
    margin: 0px auto;
    text-decoration: underline;
    font-size: 14px;
    text-decoration: none;
    border: 1px solid rgb(190, 182, 182);
    padding: 15px 20px 15px 20px;
    text-align: center;
    border-radius: 5px;
    font-size: 18px;
}

.btnNextStep:hover{
    color:rgb(201, 153, 0);
    border: 1px solid rgb(201, 153, 0);
}

.PaymentMethodsRadioLabel {
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    color: rgba(0,29,48,1);
    margin-left: 10px;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.PaymentMethodsTitle {
    font-family: "Segoe UI", "Arial"; 
    font-size: 21px;
    font-weight: bold;
    color: rgba(0,29,48,1);
    padding-left: 0px;
    margin-bottom: 30px;
}

.PaymentMethodOptions {
    margin-top: 15px;
    padding: 10px;
}

.PaymentMethodOptions > input {
    margin-bottom: 20px;
}

#ContainerResult {
    background: white;
    border-radius: 10px;
    margin-top: 15px;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
}

