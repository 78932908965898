.fondo_detalle{
    background-color: white;
    padding: 5px 10px 0px 10px;
    margin: auto;
}


.fontTitle{
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    color: rgba(0,29,48,1);
}

.fontPVP{
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,29,48,1);
}

.fontRegularBold{
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0,29,48,1);
}

.fontRegular{
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    color: rgba(0,29,48,1);
}

#Resumen label{
    margin-bottom: 0px !important;
}

@media (max-width: 768px) {

    .fontTitle{
        font-size: 19px;
    }
    
    .fontPVP{
        font-size: 17px;
    }
    
    .fontRegularBold{
        font-size: 12px;
    }
    
    .fontRegular{
        font-size: 12px !important;
    }
}