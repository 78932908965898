/* .iframeDimension
{
    

} */
.iframe-container {
    /* overflow: hidden;
     /* Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625) */
    /* padding-top: 70%; */
    position: relative; 
    margin: 0px auto;
  }
   
  .iframe-container iframe {
     border: 0;
     height: 100%;
     left: 0;
     position: relative;
     top: 0;
     width: 100%;
  }

  .fondoDetalle{
    background-color: white;
    padding: 5px 10px 0px 10px;
    margin: auto;
  }
  .fontBold{
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    color: rgba(0,29,48,1);
  }

  .fontNormalBold{
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0,29,48,1);
  }

  .fontNomal{
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    color: rgba(0,29,48,1);
  }

  .fontLimite{
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,29,48,1);
  }

  #Buttons{
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align:center;
    margin:30px;
    transition: all 100ms;
  }
  
  #Buttons > a:first-child{
    display:block;
    text-decoration:none;
    border: 1px solid rgba(0,29,48,1);
    padding:12px;
    width:300px;
    text-align:center;
    border-radius:6px;
    color: rgba(0,29,48,1);
    margin: 0px auto;
  }
  
  #Buttons #otherPayment {
    display:block;
    color: rgba(0,29,48,1);
    padding:20px;
    width:300px;
    margin: 0px auto;
    text-decoration: underline;
    font-size: 14px;
    text-decoration: none;
  }

  #Buttons > a:first-child:hover{
    border: 1px solid  rgb(201, 153, 0);
    color:rgb(201, 153, 0);
  }

  #Buttons #otherPayment :hover{
    color:rgb(201, 153, 0);
  }

  @media (max-width: 768px) {

    .fontBold{
      font-size: 19px;
    }
  
    .fontNormalBold{
      font-size: 12px;
    }
  
    .fontNomal{
      font-size: 12px !important;
    }
  
    .fontLimite{
      font-size: 11px;
    }
  }
