.BankDetails {
    font-family: "Segoe UI", "Arial"; 
	font-style:  normal;
    font-weight: normal;
	font-size: 14px;
    padding-left: 20px;
    margin-top: 15px;
    color: rgba(0,29,48,1);
}

#divAccountTitle {
    font-size: 18px;
    font-weight: bold;
}

#divAccount{
    font-size: 16px;    
}

.AccountDataBig{  
    font-size: 17px;
    font-weight: 600;
    margin-top: 25px;
}


.AccountData{  
    font-weight: 600;
}


#btnBankTransfer {
    background-color: rgb(201, 153, 0);
    border: rgb(201, 153, 0);
    color:white;
	font-family: "Segoe UI", "Arial";  
	height:45px;
    line-height:15px;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    border-radius: 5px;
    margin: 15px 0px 15px 0px;
}

#btnBankTransfer:hover{
    background-color:  rgb(168,129,5);
}



