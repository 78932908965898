a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
  }
  
  header{
    padding-bottom: 30px;
  }

  html {
    font-size: 14px;
  }
  
  .box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    padding: 1% 0 1% 0;
    background-color:white;
  }

  select {
    font-family: "Segoe UI", "Arial"; 
    border: 1px solid white !important;
  }

  select:hover{
    border: 1px solid rgba(201,153,0,1) !important;
	  box-shadow: rgba(201,153,0,1) 0px 0px 0px  !important;
  }

  select:focus{
    border: 1px solid rgba(201,153,0,1) !important;
	  box-shadow: rgba(201,153,0,1)  0px 0px 0px !important;
  }

  .logoSenator:hover{
    cursor: pointer;
  }

  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }


  @media (max-width: 768px) {
    
    .logoSenator{
      height: 25px;
    }

    .logoFlag{
      height: 15px;
    }
  }

  