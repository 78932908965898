  .fondoDetalle{
    background-color: white;
    padding: 5px 10px 0px 10px;
    margin: auto;
  }

  .fontBold{
    font-family: "Segoe UI", "Arial"; 
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    color: rgba(0,29,48,1);
  }

  .errorResponse {
    color: #EF2626; 
    font-family: "segoe UI"; 
    font-size: 21;
    font-weight: 600; 
    vertical-align:central;
    text-align:center; margin-right: 10
  }

  .successResponse {
    color: #13A78C; 
    font-family: "segoe UI"; 
    font-size: 21;
    font-weight: 600; 
    vertical-align:central;
    text-align:center; 
    margin-right: 10
  }

  .containerResultOK {
    padding-top: 20px;
    padding-bottom: 30px;
    background-color: white;
  }